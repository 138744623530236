<template>
  <page-header-wrapper :title='this.$route.query.title || this.$route.meta.title'>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <a-button style="float: right;" type='primary' @click='initialDeliver'>商品管理</a-button>
        <!-- <a-button style="float: right;" type='primary' @click='handleChildDetail'>子仓库详情</a-button> -->
        <a-button style="float: right;" type='primary' @click='initialDeliver1'>04交接20</a-button>
      </div>
      <s-table ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: "100%"}' :data='loadData'>
        <span slot='name' slot-scope='text'>
          <template>
            <Ellipsis tooltip :length='20'>{{ text }}</Ellipsis>
          </template>
        </span>
        <span slot='specs' slot-scope='text'>
          <template>
            <Ellipsis tooltip :length='15'>{{ text }}</Ellipsis>
          </template>
        </span>
        <span slot='storageBalance' slot-scope='text'>
          <template>
            <span v-if="text >= 1000">{{text}}</span>
            <span v-else style="color: red;">{{text}}</span>
          </template>
        </span>
        <span slot='fileList' slot-scope='text, record'>
          <template>
            <a-space>
              <a @click='initialDeliver3(record)'>查看</a>
            </a-space>
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a @click='handleEdit(record)'>商品入库</a>
              <a @click='initialDeliver2(record)'>上传资料</a>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal title='子仓库交接' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='商品' prop='production'>
          <a-select v-model='deliver1.form.production'
                    :default-active-first-option='false'
                    :filter-option='false'
                    :allowClear='true'
                    placeholder="请选择"
                    @change="handleChange1"
                    @search='handleSearch1'>
            <a-select-option v-for='item in prodList' :key='item.depositoryProduction'>
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='交接数量' prop='storageCount'>
          <a-input-number v-model='deliver1.form.storageCount' :parser="(value) => { return value.replace(/\D/g, '') }" />
        </a-form-model-item>
        <a-form-model-item label='交货人' prop='fromName'>
          <a-input v-model='deliver1.form.fromName' />
        </a-form-model-item>
        <a-form-model-item label="收货人" prop='toName'>
          <a-input v-model='deliver1.form.toName' />
        </a-form-model-item>
        <a-form-model-item label="交接时间" prop='storageTim'>
          <a-date-picker :valueFormat="createFormatType" v-model="deliver1.form.storageTim" showTime placeholder="选择日期时间"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='上传资料' :destroyOnClose='true' :maskClosable='true'
            :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'
            ok-text='确认' cancel-text='取消' @ok='handleDeliver2' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form2' :model='deliver2.form' :rules='deliver2.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='资料附件'>
          <a-upload
            accept=".pdf"
            :multiple="true"
            :file-list="deliver2.form.fileList"
            :customRequest="handleUpload"
            @change="handleFileChange"
          >
            <a-button type="primary"> 点击上传 </a-button>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='查看' :footer="null" :destroyOnClose='true' :maskClosable='true'
            :visible='deliver3.visible' @cancel='()=>this.deliver3.visible=false'>
      <a-descriptions :column="10">
        <template v-for="(item, index) in deliver3.form.fileList">
          <a-descriptions-item :label='index == 0 ? "检测报告" : ""' :key="index + '_0'" :span="2"></a-descriptions-item>
          <a-descriptions-item :key="index + '_1'" :span="5">{{item.fileName}}</a-descriptions-item>
          <a-descriptions-item :key="index + '_2'" :span="3">
            <a-space>
              <a @click='getDownload(item.fileUrl, item.fileName)'>下载</a>
              <a target="_blank" :href="item.fileUrl">预览</a>
              <a @click='handleDelete3(item)'>删除</a>
            </a-space>
          </a-descriptions-item>
        </template>
      </a-descriptions>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryInsideStorehouseProd, deleteInsideStorehouseProd, putInsideProdExchange, putInsideFile, deleteInsideFile, getInsideFile } from '@/api/inside'
import moment from 'moment'
import client from '@/config/oss.config.js'
import {getDownload} from '@/utils/util'
import region from '@/config/region';

const columns = [
  {
    width: 200,
    title: '商品名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    width: 200,
    title: '商品规格',
    dataIndex: 'specs',
    scopedSlots: { customRender: 'specs' }
  },
  {
    width: 100,
    title: '商品价格',
    dataIndex: 'price',
    scopedSlots: { customRender: 'price' }
  },
  {
    width: 100,
    title: '入04仓库',
    dataIndex: 'storageCount'
  },
  {
    width: 100,
    title: '交接管理员数量',
    dataIndex: 'exchangeCount',
    scopedSlots: { customRender: 'exchangeCount' }
  },
  {
    width: 100,
    title: '检测报告',
    dataIndex: 'fileList',
    scopedSlots: { customRender: 'fileList' }
  },
  {
    width: 100,
    title: '1号库存',
    dataIndex: 'storageBalance',
    scopedSlots: { customRender: 'storageBalance' }
  },
  {
    width: 200,
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

const createFormatType = 'YYYY-MM-DD HH:mm:ss';

export default {
  name: 'InsideStorehouseDetail',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      createFormatType,
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        start: baseStart,
        end: baseEnd,
      },
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: { depositoryId: this.$route.query.id, isDefault: 0 },
      loadData: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        parameter.pageMode = 0;
        return queryInsideStorehouseProd(Object.assign(parameter, this.queryParam, this.form))
          .then(datum => {
            return datum
          })
      },
      prodList: [],
      deliver1: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {          
          production: [{ required: true, message: '请选择', trigger: 'change' }],
          storageCount: [{ required: true, message: '请输入', trigger: 'change' }],
          fromName: [{ required: true, message: '请输入', trigger: 'change' }],
          toName: [{ required: true, message: '请输入', trigger: 'change' }],
          storageTim: [{ required: true, message: '请选择', trigger: 'change' }],
        },
      },
      deliver2: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {},
      },
      deliver3: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {},
      },
    }
  },
  created() {
  },
  methods: {
    moment,
    getDownload,
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    handleChange1(e) {
      console.log(e);
      this.$set(this.deliver1.form, "production", e)
    },
    handleSearch1() {
      queryInsideStorehouseProd({ pageNum: 1, pageSize: 9999,pageMode: 0, isDefault: 0 }).then(result => {
        this.prodList = result.records;
      })
    },
    async handleUpload(event) {
      const uid = event.file.uid;
      let result = await client.put('depository/' + event.file.uid, event.file);
      const index = this.deliver2.form.fileList.findIndex(item => item.uid == uid);
      if(index > -1) {
        this.deliver2.form.fileList[index].status = "done";
        this.deliver2.form.fileList[index].url = result.url;
      }
    },

    handleFileChange(info) {
      let fileList = [...info.fileList];
      // fileList = fileList.slice(-2);
      this.deliver2.form.fileList = fileList;
    },
    handleDelete3(record) {
      deleteInsideFile({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        const index = this.deliver3.form.fileList.findIndex(item => item.id == record.id)
        this.deliver3.form.fileList.splice(index, 1)
      })
    },
    async initialDeliver3(record) {
      const result = await getInsideFile({depositoryId: record.depositoryProduction})
      console.log(result);
      if (result && result.length > 0) {
        this.deliver3.form = {
          fileList: result
        }
        this.deliver3.loading = false
        this.deliver3.visible = true
      } else {
        this.$message.warning('暂无资料');
      }
    },
    initialDeliver2(record) {
      this.deliver2.form = {depository: record.depositoryProduction, fileList: []};
      this.deliver2.loading = false
      this.deliver2.visible = true
    },
    handleDeliver2() {
      this.$refs.form2.validate((result) => {
        console.log(result)
        if (result) {
          this.deliver2.loading = true;
          const requestList = [];

          for (const item of this.deliver2.form.fileList) {
            if (item.status != 'done') {
              this.$message.warning('有文件正在上传或上传失败，请重试');
              return;
            }
            requestList.push(putInsideFile({ 
              depository: this.deliver2.form.depository,
              fileUrl: item.url,
              fileName: item.name
            }))
          }
          if (requestList.length == 0) {
            this.$message.warning('至少选择一个订单');
            return;
          }
          Promise.all(requestList).then(result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver2.visible = false
            this.deliver2.loading = false;
          }).catch(() => {
            this.deliver2.loading = false;
          })
        }
      })
    },
    initialDeliver1() {
      this.handleSearch1()
      this.deliver1.form = {storageTim: moment().format(this.createFormatType)};
      this.deliver1.loading = false
      this.deliver1.visible = true
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        console.log(result)
        if (result) {
          this.deliver1.loading = true;
          putInsideProdExchange(this.deliver1.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    initialDeliver() {
      this.$router.push({ path: '/inside/prod/list'})
    },
    handleChildDetail() {
      this.$router.push({ path: '/inside/child/list'})
    },
    handleDelete(record) {
      deleteInsideStorehouseProd({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleEdit(record) {
      this.$router.push({ path: '/inside/storehouse/edit', query: { name: record.name, specs: record.specs, depositoryProduction: record.depositoryProduction }})
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = baseStart;
      this.form.end = baseEnd;
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
  }
}
</script>
